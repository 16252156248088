import { getErrorData } from '@wix/members-area-commons-ts';

import type { ErrorHandler } from '../types/controller';

export class ErrorHandlerService {
  constructor(private readonly errorHandler: ErrorHandler) {}

  extractErrorData(error: unknown) {
    return getErrorData(error, this.errorHandler.getResolvedError);
  }
}
