import type { MediaPlatformImage } from '@wix/members-domain-ts';

import { Experiment } from '../../constants/experiments';
import { ToastSkin } from '../../constants/toast';
import { maybeUploadMediaToMediaStore } from '../../services/file-upload';
import type { ImageChangeOptions, ThunkWithArgs } from '../../types';
import { getSetViewedMemberCoverAction } from '../actions';
import {
  clearInitialDataCache,
  emitProfileEditBIEvents,
  scheduleViewedMemberSync,
  showToast,
  toggleIsProfileSaving,
  toggleProfileSavedNotification,
} from './common';

export const saveCoverPhoto: ThunkWithArgs<ImageChangeOptions> =
  (image) => async (dispatch, getState, extra) => {
    const { flowAPI, errorHandlerService } = extra;
    const state = getState();

    if (!image) {
      return;
    }

    const showNewNotifications = flowAPI.experiments.enabled(
      Experiment.ShowNewNotificationsContent,
    );

    toggleIsProfileSaving(dispatch, extra);

    try {
      const updatedFields = await maybeUploadMediaToMediaStore({
        editCover: { file: image.imageUrl, name: image.name },
        services: extra,
        defaultCover: state.users.viewed.cover as MediaPlatformImage,
      });

      const updatedCover = updatedFields.cover as MediaPlatformImage;

      await extra.membersService.partialMemberUpdate(
        state.users.viewed.uid,
        updatedFields,
      );

      scheduleViewedMemberSync(extra);
      dispatch(getSetViewedMemberCoverAction(updatedCover));

      emitProfileEditBIEvents(state, updatedFields, extra);
      clearInitialDataCache(state, extra.initialDataFetchService);

      if (showNewNotifications) {
        showToast(dispatch, {
          message: flowAPI.translations.t(
            'profile-widget.change-background-cover-image.success',
          ),
        });
      } else {
        toggleProfileSavedNotification(extra);
      }
    } catch (error) {
      if (showNewNotifications) {
        const { message } = errorHandlerService.extractErrorData(error);

        showToast(dispatch, {
          message,
          skin: ToastSkin.error,
        });
      }
    } finally {
      toggleIsProfileSaving(dispatch, extra);
    }
  };
