import { withResolvedError } from '@wix/members-area-commons-ts';
import type { MediaPlatformImage } from '@wix/members-domain-ts';

import type { ErrorHandler, HttpClient } from '../../types/controller';

interface UploadFileOptions {
  uploadUrl: string;
  uploadToken: string;
  fileName: string;
  fileDataUrl: string;
}

const mediaType = 'picture';

const membersAreaFolder = 'Members Area';

const visitorsUploadsFolderId = 'visitor-uploads';

export class MediaService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorHandler: ErrorHandler,
  ) {}

  readonly uploadPicture = async ({
    uploadUrl,
    uploadToken,
    fileName,
    fileDataUrl,
  }: UploadFileOptions) => {
    const formData = new FormData();
    const blob = await this.dataUrlToBlob(fileDataUrl);

    formData.append('directory', membersAreaFolder);
    formData.append('parent_folder_id', visitorsUploadsFolderId);
    formData.append('upload_token', uploadToken);
    formData.append('media_type', mediaType);
    formData.append('file', blob, fileName);

    return this.handlePictureUploadRequest(uploadUrl, formData);
  };

  private readonly handlePictureUploadRequest = async (
    uploadUrl: string,
    formData: FormData,
  ) => {
    const requestFn = () =>
      this.httpClient.post<MediaPlatformImage[]>(uploadUrl, formData);

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    const { width, height, file_name, original_file_name } = data[0];

    return { width, height, file_name, original_file_name };
  };

  private readonly dataUrlToBlob = async (url: string) => {
    const requestFn = () =>
      this.httpClient.get<Blob>(url, {
        responseType: 'blob',
      });

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  };
}
