import {
  memberJoinedCommunityAttempt,
  memberJoinedCommunityJoinedAfterPopUpUou,
  memberLeftCommunityAttempt,
  memberLeftCommunityLeftAfterPopup,
} from '@wix/bi-logger-members-app-uou/v2';
import type { RoleId } from '@wix/members-domain-ts';
import { PrivacyStatus } from '@wix/members-domain-ts';

import { Experiment } from '../../../constants/experiments';
import { ToastSkin } from '../../../constants/toast';
import { getCommonBIEventsData } from '../../../services/bi-event';
import {
  Notification,
  openModalWithCallback,
  openNotification,
} from '../../../services/modal';
import type {
  GetResolvedErrorFn,
  PublicMember,
  ThunkExtra,
  ThunkWithArgs,
} from '../../../types';
import { Origin } from '../../../types';
import { getSetUsersAction } from '../../actions';
import type { RootState } from '../../root-reducer';
import {
  clearInitialDataCache,
  scheduleViewedMemberSync,
  showToast,
} from '../common';

interface OpenCommunityModalOptions {
  state: RootState;
  extra: ThunkExtra;
  roleId: RoleId;
  privacyStatus: PrivacyStatus;
  onConfirm: () => Promise<void>;
}

interface GetMembersOptions {
  extra: ThunkExtra;
  viewedUid: string;
  currentUid: string;
  getResolvedError: GetResolvedErrorFn;
}

const getNotification = (privacyStatus: PrivacyStatus) =>
  privacyStatus === PrivacyStatus.Public
    ? Notification.JoinedCommunity
    : Notification.LeftCommunity;

const openCommunityModal = ({
  state,
  extra,
  roleId,
  privacyStatus,
  onConfirm,
}: OpenCommunityModalOptions) => {
  const {
    compId,
    platformAPIs,
    wixCodeApi,
    experiments,
    biLogger,
    flowAPI,
    metaData,
  } = extra;
  const payload = { originalAppComponent: Origin.Profile };

  if (privacyStatus === PrivacyStatus.Public) {
    biLogger?.report(
      memberJoinedCommunityAttempt(
        getCommonBIEventsData(flowAPI, state, metaData),
      ),
    );
  } else {
    biLogger?.report(
      memberLeftCommunityAttempt(
        getCommonBIEventsData(flowAPI, state, metaData),
      ),
    );
  }

  openModalWithCallback({
    compId,
    modalType: roleId,
    payload,
    platformAPIs,
    wixCodeApi,
    experiments,
    onConfirm,
  });
};

const openCommunityNotification = (
  privacyStatus: PrivacyStatus,
  { compId, flowAPI, wixCodeApi, experiments }: ThunkExtra,
) => {
  openNotification({
    compId,
    wixCodeApi,
    experiments,
    notification: getNotification(privacyStatus),
    isMobile: flowAPI.environment.isMobile,
  });
};

const getMembers = async ({
  extra,
  viewedUid,
  currentUid,
  getResolvedError,
}: GetMembersOptions) => {
  const { membersService } = extra;
  const getMemberPromises: Promise<PublicMember>[] = [];

  const getCurrentMemberPromise = membersService.getMember(currentUid);

  getMemberPromises.push(getCurrentMemberPromise);

  if (viewedUid === currentUid) {
    getMemberPromises.push(getCurrentMemberPromise);
  } else {
    const getViewedMemberPromise = membersService.getMember(viewedUid);

    getMemberPromises.push(getViewedMemberPromise);
  }

  const [current, viewed] = await Promise.all(getMemberPromises);

  return { current, viewed };
};

const communityRoleActionFactory = (
  privacyStatus: PrivacyStatus,
): ThunkWithArgs<RoleId> => {
  return (roleId) => (dispatch, getState, extra) => {
    const state = getState();
    const { viewed, current } = state.users;
    const { membersService, errorHandlerService, biLogger, flowAPI, metaData } =
      extra;

    const {
      errorHandler,
      experiments,
      translations: { t },
    } = flowAPI;
    const { getResolvedError } = errorHandler;

    const onConfirm = async () => {
      const showNewNotifications = experiments.enabled(
        Experiment.ShowNewNotificationsContent,
      );

      try {
        await membersService.setCurrentMemberPrivacyStatus(privacyStatus);
        const currentUid = current ? current.uid : '';

        const members = await getMembers({
          extra,
          viewedUid: viewed.uid,
          currentUid,
          getResolvedError,
        });

        scheduleViewedMemberSync(extra);
        dispatch(getSetUsersAction(members));

        if (privacyStatus === PrivacyStatus.Public) {
          biLogger?.report(
            memberJoinedCommunityJoinedAfterPopUpUou(
              getCommonBIEventsData(flowAPI, state, metaData),
            ),
          );
        } else {
          biLogger?.report(
            memberLeftCommunityLeftAfterPopup(
              getCommonBIEventsData(flowAPI, state, metaData),
            ),
          );
        }

        clearInitialDataCache(state, extra.initialDataFetchService);

        if (showNewNotifications) {
          const messageKey =
            privacyStatus === PrivacyStatus.Public
              ? 'community.join.success'
              : 'community.leave.success';

          showToast(dispatch, {
            message: t(messageKey),
          });
        } else {
          openCommunityNotification(privacyStatus, extra);
        }
      } catch (error) {
        if (showNewNotifications) {
          const { message } = errorHandlerService.extractErrorData(error);

          showToast(dispatch, {
            message,
            skin: ToastSkin.error,
          });
        }
      }
    };

    openCommunityModal({ state, extra, roleId, privacyStatus, onConfirm });
  };
};

export const joinCommunity = communityRoleActionFactory(PrivacyStatus.Public);

export const leaveCommunity = communityRoleActionFactory(PrivacyStatus.Private);
