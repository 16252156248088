import { followButtonClickedUou } from '@wix/bi-logger-members-app-uou/v2';
import { RoleId } from '@wix/members-domain-ts';

import { FFChange } from '../../constants/common';
import { LIGHTBOX_TPA_PAGE_ID } from '../../constants/lightbox';
import { getCommonBIEventsData } from '../../services/bi-event';
import { requestLogin } from '../../services/login-service';
import { Applications } from '../../services/public-api-store';
import type {
  FollowingFollowersTab,
  StoreState,
  Thunk,
  ThunkDispatch,
  ThunkExtra,
} from '../../types';
import { Origin } from '../../types';
import { getFollowOrUnfollowAction } from '../actions';
import { isMemberInCommunity } from '../selectors';
import { clearInitialDataCache, scheduleViewedMemberSync } from './common';
import { updateViewedMemberFollowingCount } from './data-sync';
import { joinCommunity } from './role-action/community';

type ShowFollowingFollowersOptions = {
  extra: ThunkExtra;
  activeTab: FollowingFollowersTab;
  dispatch: ThunkDispatch;
  getState: () => StoreState;
};

export const followOrUnfollow: Thunk = () => {
  return async (dispatch, getState, extra) => {
    const { wixCodeApi, membersService, biLogger, flowAPI, metaData } = extra;
    const state = getState();
    const { current, viewed } = state.users;

    if (!current) {
      requestLogin(wixCodeApi);
      return;
    }

    if (!isMemberInCommunity(current)) {
      joinCommunity(RoleId.JOIN_COMMUNITY)(dispatch, getState, extra);
      return;
    }

    scheduleViewedMemberSync(extra);
    dispatch(getFollowOrUnfollowAction());

    biLogger?.report(
      followButtonClickedUou({
        ...getCommonBIEventsData(flowAPI, state, metaData),
        origin: Origin.Profile,
        member_followed: viewed.uid,
        is_followed: !viewed.isSubscribed,
      }),
    );

    clearInitialDataCache(state, extra.initialDataFetchService);
    membersService.toggleMemberFollowStatus(viewed.uid, viewed.isSubscribed);
  };
};

const showFollowingFollowers = async ({
  activeTab,
  dispatch,
  extra,
  getState,
}: ShowFollowingFollowersOptions) => {
  const { viewed } = getState().users;
  const publicAPI = await extra.getPublicAPI(Applications.MembersArea);

  const onFollowingChange = async (type: 'follow' | 'unfollow') => {
    if (type === 'follow') {
      await updateViewedMemberFollowingCount(FFChange.Increase)(
        dispatch,
        getState,
        extra,
      );
    } else {
      await updateViewedMemberFollowingCount(FFChange.Decrease)(
        dispatch,
        getState,
        extra,
      );
    }
  };

  return publicAPI?.openLightbox(LIGHTBOX_TPA_PAGE_ID.followingFollowers, {
    activeTab,
    title: viewed.name,
    memberId: viewed.uid,
    onFollowingChange,
  });
};

export const showFollowers: Thunk = () => {
  return async (dispatch, getState, extra) => {
    showFollowingFollowers({
      getState,
      extra,
      dispatch,
      activeTab: 'followers',
    });
  };
};

export const showFollowing: Thunk = () => {
  return async (dispatch, getState, extra) => {
    showFollowingFollowers({
      getState,
      extra,
      dispatch,
      activeTab: 'following',
    });
  };
};
