import { withResolvedError } from '@wix/members-area-commons-ts';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

import type { InjectedGlobalSettings } from '../../types';
import { Origin } from '../../types';
import type { ErrorHandler, HttpClient } from '../../types/controller';

type ViewMode =
  ControllerParams['controllerConfig']['wixCodeApi']['window']['viewMode'];

type SettingsServiceOptions = {
  componentId: string;
  baseUrl: string;
  httpClient: HttpClient;
  errorHandler: ErrorHandler;
};

export class SettingsService {
  private readonly componentId: string;
  private readonly baseUrl: string;
  private readonly httpClient: HttpClient;
  private readonly errorHandler: ErrorHandler;

  constructor({
    componentId,
    baseUrl,
    httpClient,
    errorHandler,
  }: SettingsServiceOptions) {
    this.componentId = componentId;
    this.baseUrl = baseUrl;
    this.httpClient = httpClient;
    this.errorHandler = errorHandler;
  }

  async getGlobalSettings(viewMode: ViewMode) {
    const url = `${this.baseUrl}/settings/global`;
    const queryParams = this.getQueryParams(viewMode);

    const requestFn = () =>
      this.httpClient.get<InjectedGlobalSettings>(`${url}?${queryParams}`);

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  }

  async patchGlobalSettings(globalSettings: Partial<InjectedGlobalSettings>) {
    const url = `${this.baseUrl}/settings/merge/global`;
    const queryParams = this.getQueryParams('Editor');

    const requestFn = () =>
      this.httpClient.put(`${url}?${queryParams}`, globalSettings);

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  }

  private getQueryParams(viewMode: ViewMode) {
    const viewModeLowerCased = viewMode.toLocaleLowerCase();
    return `compId=${this.componentId}&appComponent=${Origin.Profile}&viewMode=${viewModeLowerCased}`;
  }
}
