import type { MediaPlatformImage } from '@wix/members-domain-ts';

import { Experiment } from '../../constants/experiments';
import { ToastSkin } from '../../constants/toast';
import { getUpdatedCoverPosition } from '../../services/cover-utils';
import { maybeUploadMediaToMediaStore } from '../../services/file-upload';
import type {
  CoverRepositionOptions,
  StoreState,
  Thunk,
  ThunkDispatch,
  ThunkExtra,
  ThunkWithArgs,
} from '../../types';
import {
  getSetEditCoverAction,
  getSetEditCoverPositionAction,
  getSetViewedMemberCoverAction,
  getToggleIsCoverRepositionModeAction,
} from '../actions';
import {
  clearInitialDataCache,
  emitProfileEditBIEvents,
  scheduleViewedMemberSync,
  showToast,
  toggleIsProfileSaving,
  toggleProfileSavedNotification,
} from './common';

export const leaveCoverRepositionMode: Thunk = () => (dispatch, getState) => {
  const { profilePage } = getState();
  const { isEditing } = profilePage;

  if (!isEditing) {
    dispatch(getSetEditCoverAction(null));
  } else {
    dispatch(getSetEditCoverPositionAction());
  }

  dispatch(getToggleIsCoverRepositionModeAction());
};

const getUpdatedFields = async (state: StoreState, extra: ThunkExtra) => {
  const { profilePage, users } = state;

  return maybeUploadMediaToMediaStore({
    editCover: profilePage.editCover,
    services: extra,
    defaultCover: users.viewed.cover as MediaPlatformImage,
  });
};

const updateMemberCover = async (
  dispatch: ThunkDispatch,
  state: StoreState,
  extra: ThunkExtra,
) => {
  const { uid } = state.users.viewed;
  const { membersService } = extra;

  const updatedFields = await getUpdatedFields(state, extra);
  const updatedCover = updatedFields.cover as MediaPlatformImage;
  await membersService.partialMemberUpdate(uid, updatedFields);

  scheduleViewedMemberSync(extra);
  dispatch(getSetViewedMemberCoverAction(updatedCover));
  dispatch(getSetEditCoverAction(null));

  return updatedFields;
};

export const saveCoverReposition: Thunk =
  () => async (dispatch, getState, extra) => {
    const { flowAPI, errorHandlerService } = extra;
    const state = getState();

    dispatch(getToggleIsCoverRepositionModeAction());

    if (state.profilePage.isEditing) {
      return;
    }

    const showNewNotifications = flowAPI.experiments.enabled(
      Experiment.ShowNewNotificationsContent,
    );

    toggleIsProfileSaving(dispatch, extra);

    try {
      const updatedFields = await updateMemberCover(dispatch, state, extra);

      emitProfileEditBIEvents(state, updatedFields, extra);
      clearInitialDataCache(state, extra.initialDataFetchService);

      if (showNewNotifications) {
        showToast(dispatch, {
          message: flowAPI.translations.t(
            'profile-widget.update-background-cover-image.success',
          ),
        });
      } else {
        toggleProfileSavedNotification(extra);
      }
    } catch (error) {
      if (showNewNotifications) {
        const { message } = errorHandlerService.extractErrorData(error);

        showToast(dispatch, {
          message,
          skin: ToastSkin.error,
        });
      }
    } finally {
      toggleIsProfileSaving(dispatch, extra);
    }
  };

export const changeCoverPosition: ThunkWithArgs<CoverRepositionOptions> =
  ({ coverWidth, movement, isMobile }) =>
  (dispatch, getState) => {
    const { users, profilePage } = getState();
    const { editCover } = profilePage;
    const { viewed } = users;

    const position = getUpdatedCoverPosition({
      member: viewed,
      editCover,
      coverWidth,
      movement,
      isMobile,
    });

    dispatch(getSetEditCoverPositionAction(position));
  };
