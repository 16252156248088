import { getMemberPrivacySettings as getMemberPrivacySettingsAmbassador } from '@wix/ambassador-members-v1-member-privacy-settings/http';
import { Candidates } from '@wix/ambassador-members-v1-member-privacy-settings/types';
import { withResolvedError } from '@wix/members-area-commons-ts';

import type { ErrorHandler, HttpClient } from '../types/controller';

export const getIsPublicMemberCandidateNoOne = async (
  httpClient: HttpClient,
  errorHandler: ErrorHandler,
) => {
  const requestFn = () =>
    httpClient.request(getMemberPrivacySettingsAmbassador({}));

  const {
    data: { memberPrivacySettings },
  } = await withResolvedError(requestFn, errorHandler, {
    errorCodesMap: {},
  });

  return memberPrivacySettings?.publicMemberCandidates === Candidates.NO_ONE;
};
