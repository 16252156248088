import type { Interaction } from '../types';
import type { FlowAPI } from '../types/controller';
import type { HTTPError } from './error';

type LoggerOptions = { tags: { [id: string]: string } };

enum ErrorSeverity {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warning',
  Log = 'log',
  Info = 'info',
  Debug = 'debug',
  Critical = 'critical',
}

export interface MonitoringService {
  toMonitored: <T>(interaction: Interaction, promise: Promise<T>) => Promise<T>;
  log: (message: string, options?: LoggerOptions) => void;
}

const getErrorDetails = (error: HTTPError | Error) => {
  return 'errorDetails' in error ? error.errorDetails : null;
};

const captureException = (
  panorama: FlowAPI['panoramaClient'],
  interactionName: string,
  error: HTTPError | Error,
) => {
  const tags = { ...getErrorDetails(error), interactionName };
  return panorama?.errorMonitor().reportError(error, { tags });
};

const captureMessage = (
  panorama: FlowAPI['panoramaClient'],
  message: string,
  options?: LoggerOptions,
) => {
  return panorama?.errorMonitor().reportError(new Error(message), {
    severity: ErrorSeverity.Info,
    tags: options?.tags,
  });
};

export const initMonitoringService = (
  panorama: FlowAPI['panoramaClient'],
): MonitoringService => ({
  toMonitored: async (interaction, promise) => {
    try {
      panorama?.transaction(interaction).start();
      const response = await promise;
      panorama?.transaction(interaction).finish();
      return response;
    } catch (error) {
      captureException(panorama, interaction, error as HTTPError | Error);
      throw error;
    }
  },
  log: (message, options) => captureMessage(panorama, message, options),
});
