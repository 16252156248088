import type { TFunction } from '@wix/yoshi-flow-editor';

export const getAssignRoleApplicationErrors = (
  t: TFunction,
  memberName: string,
) => ({
  MEMBER_BLOCKED: () => ({
    message: t('MemberRoles.action_set.assign_role.error.member-blocked', {
      memberName,
    }),
  }),
  ROLE_DOES_NOT_EXIST: () => ({
    message: t('MemberRoles.action_set.assign_role.error.role-does-not-exist'),
  }),
});
