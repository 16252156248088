import type { TFunction } from '@wix/yoshi-flow-editor';

export const getReportMemberApplicationErrors = (
  t: TFunction,
  reportedMemberName: string,
) =>
  ({
    SELF_REPORTING_FORBIDDEN: false,
    CANNOT_REPORT_BLOCKED_MEMBER: () => ({
      message: t(
        'MemberRoles.action_set.report.error.cannot-report-blocked-member',
        {
          memberName: reportedMemberName,
        },
      ),
    }),
  } as const);
