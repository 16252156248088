import { withResolvedError } from '@wix/members-area-commons-ts';
import type { BadgeType } from '@wix/members-badge-lib';

import type { ErrorHandler, HttpClient } from '../../types/controller';

interface BadgeListResponse {
  badges: BadgeType[];
}

type BadgesServiceOptions = {
  baseUrl: string;
  httpClient: HttpClient;
  errorHandler: ErrorHandler;
};

export class BadgesService {
  private readonly baseUrl: string;
  private readonly httpClient: HttpClient;
  private readonly errorHandler: ErrorHandler;

  constructor({ baseUrl, httpClient, errorHandler }: BadgesServiceOptions) {
    this.baseUrl = baseUrl;
    this.httpClient = httpClient;
    this.errorHandler = errorHandler;
  }

  readonly getBadgeList = async () => {
    const url = `${this.baseUrl}/badges`;

    const requestFn = () => this.httpClient.get<BadgeListResponse>(url);

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data.badges;
  };
}
