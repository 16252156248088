import { withResolvedError } from '@wix/members-area-commons-ts';

import type { InjectedGlobalSettings, PublicMember, RolesMap } from '../types';
import type { ErrorHandler, HttpClient } from '../types/controller';

type ProfileCardMiddlewareServiceOptions = {
  isEditor: boolean;
  httpClient: HttpClient;
  errorHandler: ErrorHandler;
};

export class ProfileCardMiddlewareService {
  private readonly isEditor: boolean;
  private readonly httpClient: HttpClient;
  private readonly errorHandler: ErrorHandler;

  constructor({
    isEditor,
    httpClient,
    errorHandler,
  }: ProfileCardMiddlewareServiceOptions) {
    this.isEditor = isEditor;
    this.httpClient = httpClient;
    this.errorHandler = errorHandler;
  }

  async getMember(uid: string) {
    const requestFn = () =>
      this.httpClient.get<PublicMember>(
        `/_serverless/profile-card-middleware/member/${uid}`,
      );

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  }

  async getRolesMap() {
    const requestFn = () =>
      this.httpClient.get<RolesMap>(
        `/_serverless/profile-card-middleware/roles-map`,
      );

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  }

  async getGlobalSettings() {
    const requestFn = () =>
      this.httpClient.get<InjectedGlobalSettings>(
        `/_serverless/profile-card-middleware/global-settings`,
        { params: { isEditor: this.isEditor ? 'true' : 'false' } },
      );

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  }
}
