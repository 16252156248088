import { RoleId } from '@wix/members-domain-ts';

import { Experiment } from '../../../constants/experiments';
import { ToastSkin } from '../../../constants/toast';
import { openModalWithCallback } from '../../../services/modal';
import type { ThunkWithArgs } from '../../../types';
import { Origin } from '../../../types';
import { getSetViewedMemberAction } from '../../actions';
import {
  clearInitialDataCache,
  scheduleViewedMemberSync,
  showToast,
} from '../common';

type OppositeRolesMap = { [key in RoleId]?: RoleId };

const oppositeRolesMap: OppositeRolesMap = {
  [RoleId.BLOCK_MEMBER]: RoleId.UNBLOCK_MEMBER,
  [RoleId.SET_FORUM_MODERATOR]: RoleId.UNSET_FORUM_MODERATOR,
  [RoleId.SET_BLOG_WRITER]: RoleId.UNSET_BLOG_WRITER,
};

const rolesIdsWithPictures = [
  RoleId.SET_FORUM_MODERATOR,
  RoleId.UNSET_FORUM_MODERATOR,
  RoleId.SET_BLOG_WRITER,
  RoleId.UNSET_BLOG_WRITER,
];

export const modalRoleAction: ThunkWithArgs<RoleId> =
  (roleId) => (dispatch, getState, extra) => {
    const {
      compId,
      wixCodeApi,
      platformAPIs,
      membersService,
      errorHandlerService,
      flowAPI,
    } = extra;
    const state = getState();
    const { viewed } = state.users;

    const { experiments } = flowAPI;

    const applicableRoleId = viewed.roles.includes(roleId)
      ? oppositeRolesMap[roleId] ?? roleId
      : roleId;
    const hasPicture = rolesIdsWithPictures.includes(roleId);

    const payload = {
      ...(hasPicture && {
        memberName: viewed.name,
        memberPicture: viewed.picture,
      }),
      originalAppComponent: Origin.Profile,
    };

    const onConfirm = async () => {
      const { uid, roles, name: memberName } = viewed;

      clearInitialDataCache(state, extra.initialDataFetchService);
      scheduleViewedMemberSync(extra);

      const showNewNotifications = experiments.enabled(
        Experiment.ShowNewNotificationsContent,
      );

      try {
        await membersService.applyRoleAction({
          uid,
          roles,
          roleId,
          memberName,
        });

        const viewedMember = await membersService.getMember(uid);
        dispatch(getSetViewedMemberAction(viewedMember));
      } catch (error) {
        if (showNewNotifications) {
          const { message } = errorHandlerService.extractErrorData(error);

          showToast(dispatch, {
            message,
            skin: ToastSkin.error,
          });
        }
      }
    };

    openModalWithCallback({
      compId,
      modalType: applicableRoleId,
      payload,
      platformAPIs,
      wixCodeApi,
      experiments,
      onConfirm,
    });
  };
