import { createSlice } from '@reduxjs/toolkit';

import { ToastSkin } from '../../constants/toast';
import type { InjectedUI, Reducer } from '../../types';

export type SetShowToastPayload = {
  skin?: any;
  message: string;
};

const name = 'ui';
const defaultToastSkin = ToastSkin.success;

const initialState: InjectedUI = {
  toast: {
    message: '',
    skin: defaultToastSkin,
    isVisible: false,
  },
};

const showToast: Reducer<InjectedUI, SetShowToastPayload> = (
  state,
  { payload },
) => ({
  ...state,
  toast: {
    skin: payload.skin || defaultToastSkin,
    message: payload.message,
    isVisible: true,
  },
});

const hideToast: Reducer<InjectedUI> = (state) => ({
  ...state,
  toast: {
    ...initialState.toast,
  },
});

const reducers = {
  showToast,
  hideToast,
};

const uiSlice = createSlice({ name, initialState, reducers });

export default uiSlice;
