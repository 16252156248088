import type { TFunction } from '@wix/yoshi-flow-editor';

export const getBlockedMemberApplicationErrors = (
  memberName: string,
  t: TFunction,
) =>
  ({
    MEMBER_ALREADY_BLOCKED: () => ({
      message: t(
        'MemberRoles.action_set.block_member.error.member-already-blocked',
        {
          memberName,
        },
      ),
    }),
    SELF_BLOCKING_FORBIDDEN: false,
    ADMIN_BLOCKING_FORBIDDEN: false,
  } as const);
