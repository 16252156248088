import { blockMember } from '@wix/ambassador-members-v1-member-to-member-block/http';
import { withResolvedError } from '@wix/members-area-commons-ts';
import type { TFunction } from '@wix/yoshi-flow-editor';

import type { ErrorHandler, HttpClient } from '../../types/controller';
import { getBlockedMemberApplicationErrors } from './application-errors';

type BlockMemberServiceOptions = {
  httpClient: HttpClient;
  errorHandler: ErrorHandler;
  t: TFunction;
};

export class BlockMemberService {
  private readonly httpClient: HttpClient;
  private readonly errorHandler: ErrorHandler;
  private readonly t: TFunction;

  constructor({ httpClient, errorHandler, t }: BlockMemberServiceOptions) {
    this.httpClient = httpClient;
    this.errorHandler = errorHandler;
    this.t = t;
  }

  async blockMember(memberId: string, memberName: string) {
    const requestFn = () => this.httpClient.request(blockMember({ memberId }));

    return withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {
        applicationError: getBlockedMemberApplicationErrors(memberName, this.t),
      },
    });
  }
}
