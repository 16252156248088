import type { Profile } from '@wix/ambassador-members-v1-member/types';

import { myAccountAppDefinitionId } from '../../constants/app-definition-id';
import { Experiment } from '../../constants/experiments';
import { myAccountPageId } from '../../constants/section-id';
import { ToastSkin } from '../../constants/toast';
import { MY_ACCOUNT_PAGE_WIDGET_ID } from '../../constants/widgets-ids';
import { Applications } from '../../services/public-api-store';
import type {
  ImageChangeOptions,
  Nullable,
  PublicMemberInfoUpdateOptions,
  StoreState,
  Thunk,
  ThunkDispatch,
  ThunkExtra,
  ThunkWithArgs,
} from '../../types';
import {
  getSetCurrentMemberDetails,
  getSetEditCoverAction,
  getSetEditPictureAction,
  getSetViewedMemberDetails,
  getStopEditingProfileAction,
  getToggleIsCoverLoadingAction,
  getToggleIsCoverRepositionModeAction,
} from '../actions';
import { getCanEdit } from '../selectors';
import { showToast } from './common';

interface UpdateMemberDetailsOptions {
  state: StoreState;
  profile: Profile;
  dispatch: ThunkDispatch;
}

export const enterCoverRepositionMode = () => {
  return getToggleIsCoverRepositionModeAction();
};

const navigateToMyAccount = async (
  { users, site }: StoreState,
  { getPublicAPI }: ThunkExtra,
) => {
  const membersAreaAPI = await getPublicAPI(Applications.MembersArea);
  const { uid, slug } = users.viewed;

  return membersAreaAPI?.navigateToSection({
    appDefinitionId: myAccountAppDefinitionId,
    sectionId: myAccountPageId,
    memberId: slug || uid,
    widgetId: MY_ACCOUNT_PAGE_WIDGET_ID,
  });
};

const updateMemberDetailsInState = ({
  state,
  profile,
  dispatch,
}: UpdateMemberDetailsOptions) => {
  const canEdit = getCanEdit(state);

  const memberDetails = {
    name: profile.nickname ?? '',
    title: profile.title ?? '',
  };

  if (state.users.current) {
    dispatch(getSetCurrentMemberDetails(memberDetails));
  }

  if (canEdit) {
    dispatch(getSetViewedMemberDetails(memberDetails));
  }
};

export const toggleIsEditingProfile: Thunk =
  () => (dispatch, getState, extra) =>
    navigateToMyAccount(getState(), extra);

export const stopEditingProfile: Thunk = () => (dispatch) => {
  dispatch(getStopEditingProfileAction());
};

export const setEditPicture = (options: ImageChangeOptions) =>
  getSetEditPictureAction(options);

export const setEditCover: ThunkWithArgs<Nullable<ImageChangeOptions>> =
  (options) => (dispatch) => {
    dispatch(getToggleIsCoverLoadingAction());
    dispatch(getSetEditCoverAction(options));
    dispatch(getToggleIsCoverLoadingAction());
  };

export const updateProfileDisplayInfo: ThunkWithArgs<
  PublicMemberInfoUpdateOptions
> =
  (payload) =>
  async (
    dispatch,
    getState,
    { membersService, errorHandlerService, flowAPI },
  ) => {
    const {
      experiments,
      translations: { t },
    } = flowAPI;

    const showNewNotifications = experiments.enabled(
      Experiment.ShowNewNotificationsContent,
    );

    try {
      const { member } = await membersService.updateMemberPublicInfo(payload);

      updateMemberDetailsInState({
        state: getState(),
        profile: member?.profile ?? {},
        dispatch,
      });

      showToast(dispatch, {
        message: t('profile-widget.toast.display-info-updated'),
      });
    } catch (error) {
      if (showNewNotifications) {
        const { message } = errorHandlerService.extractErrorData(error);

        return showToast(dispatch, {
          skin: ToastSkin.error,
          message,
        });
      }

      return showToast(dispatch, {
        skin: ToastSkin.error,
        message: t('profile-widget.toast.display-info-update-error'),
      });
    }
  };
